var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "quiz"
    }
  }, [_c('b-row', [_c('b-col', [_c('h2', {
    staticClass: "mt-1"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SettingsIcon",
      "size": "30"
    }
  }), _vm._v(" Quiz ")], 1)])], 1), _vm._l(_vm.form.questions, function (question, index) {
    return _c('b-row', {
      key: index,
      staticClass: "mt-1"
    }, [_c('b-col', [_c('b-card', [_c('b-card', {
      staticClass: "card-quiz-pre"
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('label', {
      attrs: {
        "for": "textarea-default"
      }
    }, [_vm._v("Dificuldade")]), _c('v-select', {
      staticClass: "mb-3",
      attrs: {
        "id": "dificuldade",
        "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
        "selectable": function selectable(option) {
          return option.value != null;
        },
        "options": _vm.dificuldadeOptions,
        "item-value": "value",
        "item-text": "name"
      },
      model: {
        value: question.dificuldade,
        callback: function callback($$v) {
          _vm.$set(question, "dificuldade", $$v);
        },
        expression: "question.dificuldade"
      }
    })], 1), _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('label', {
      attrs: {
        "for": "textarea-default"
      }
    }, [_vm._v("Conteúdo da pergunta")]), _c('b-form-textarea', {
      staticClass: "mb-3",
      attrs: {
        "id": "textarea-default",
        "placeholder": "Inserir texto",
        "rows": "3"
      },
      model: {
        value: question.question,
        callback: function callback($$v) {
          _vm.$set(question, "question", $$v);
        },
        expression: "question.question"
      }
    })], 1)], 1), _vm._l(question.options, function (option) {
      return _c('b-row', {
        key: option.letter
      }, [_c('b-col', {
        attrs: {
          "lg": "0"
        }
      }, [_c('b-button', {
        staticClass: "button-option",
        attrs: {
          "variant": option.isAnswer ? 'primary' : ''
        },
        on: {
          "click": function click($event) {
            return _vm.setCorrectQuestion(question, option.letter);
          }
        }
      }, [_c('span', [_vm._v(_vm._s(option.letter))])])], 1), _c('b-col', [_c('label', {
        attrs: {
          "for": "textarea-default"
        }
      }, [_vm._v("Conteúdo da alternativa")]), _c('b-form-textarea', {
        attrs: {
          "id": "textarea-default",
          "placeholder": "Inserir texto",
          "rows": "3"
        },
        model: {
          value: option.description,
          callback: function callback($$v) {
            _vm.$set(option, "description", $$v);
          },
          expression: "option.description"
        }
      })], 1)], 1);
    }), index > 0 ? _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "button-right mt-2",
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.removeQuestion(index);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "MinusIcon"
      }
    }), _c('span', {
      staticClass: "align-middle"
    }, [_vm._v("Remover")])], 1) : _vm._e()], 2)], 1)], 1)], 1);
  }), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "button-right",
    attrs: {
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ListIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    on: {
      "click": function click($event) {
        return _vm.addNewQuestion();
      }
    }
  }, [_vm._v("Adicionar Questão")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }