<template>
  <div id="quiz">
    <b-row>
      <b-col>
        <h2 class="mt-1">
          <feather-icon icon="SettingsIcon" size="30" class="mr-50" />
          Quiz
        </h2>
      </b-col>
    </b-row>
    <b-row
      v-for="(question, index) of form.questions"
      :key="index"
      class="mt-1"
    >
      <b-col>
        <b-card>
          <b-card class="card-quiz-pre">
            <b-row>
              <b-col cols="6">
                <label for="textarea-default">Dificuldade</label>
                <v-select
                  id="dificuldade"
                  v-model="question.dificuldade"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :selectable="option => option.value != null"
                  :options="dificuldadeOptions"
                  item-value="value"
                  item-text="name"
                  class="mb-3"
                />
              </b-col>
              <b-col cols="12">
                <label for="textarea-default">Conteúdo da pergunta</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="question.question"
                  placeholder="Inserir texto"
                  rows="3"
                  class="mb-3"
                />
              </b-col>
            </b-row>
            <!--alternativa start -->
            <b-row v-for="option of question.options" :key="option.letter">
              <b-col lg="0">
                <b-button
                  :variant="option.isAnswer ? 'primary' : ''"
                  class="button-option"
                  @click="setCorrectQuestion(question, option.letter)"
                >
                  <span>{{ option.letter }}</span>
                </b-button>
              </b-col>
              <b-col>
                <label for="textarea-default">Conteúdo da alternativa</label>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="Inserir texto"
                  rows="3"
                  v-model="option.description"
                />
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="button-right mt-2"
              @click="removeQuestion(index)"
              v-if="index > 0"
            >
              <feather-icon icon="MinusIcon" class="mr-50" />
              <span class="align-middle">Remover</span>
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="button-right mt-2"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Adicionar Alternativa</span>
            </b-button> -->
          </b-card>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col
        ><b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="button-right"
          variant="primary"
        >
          <feather-icon icon="ListIcon" class="mr-50" />
          <span class="align-middle" @click="addNewQuestion()"
            >Adicionar Questão</span
          >
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BAvatar,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dificuldadeOptions: [
        { value: 1, label: 'Fácil' },
        { value: 2, label: 'Médio' },
        { value: 3, label: 'Difícil' },
      ],
    }
  },
  methods: {
    removeQuestion(index) {
      this.form.questions.splice(index, 1)
    },
    setCorrectQuestion(question, letter) {
      console.log(question)
      // eslint-disable-next-line no-param-reassign
      question.correctAnswer = letter
      question.options.forEach(q => {
        // eslint-disable-next-line no-param-reassign
        q.isAnswer = q.letter === letter
      })
    },
    addNewQuestion() {
      console.log(this.form?.questions)
      if (!this.form.questions) {
        this.form.questions = []
      }
      this.form.questions.push({
        question: '',
        dificuldade: null,
        correctAnswer: 'A',
        options: [
          {
            letter: 'A',
            description: '',
            isAnswer: true,
          },
          {
            letter: 'B',
            description: '',
            isAnswer: false,
          },
          {
            letter: 'C',
            description: '',
            isAnswer: false,
          },
          {
            letter: 'D',
            description: '',
            isAnswer: false,
          },
        ],
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.button-option {
  height: 40px;
  width: 40px;
  padding: 0;
}
</style>
